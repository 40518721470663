import { defineStore, acceptHMRUpdate } from 'pinia'
import { saleService } from '@/services/SaleService'
import { authenticationService } from '../services/AuthenticationService'

export const useSaleStore = defineStore('SaleStore', {
  state: () => {
    return {
      subsidiaryId: authenticationService.currentUserValue.user.sbuUser ? authenticationService.currentUserValue.user.sbuUser.sbuId : null,
      currentUser: authenticationService.currentUserValue ? authenticationService.currentUserValue.user : null,
      currentOther: authenticationService.currentUserValue ? authenticationService.currentUserValue.other : null,
      currentFrontline: authenticationService.currentUserValue ? authenticationService.currentUserValue.other : null,
      cusStartDate: '2023-01-20',
      transStartDate: '2023-01-20',
      sectorStartDate: '2023-01-20',
      cusEndDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
      transEndDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
      sectorEndDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
      periods: [{ label: 'Daily', value: 1 }, { label: 'Weekly', value: 7 }, { label: 'Monthly', value: 30 }, { label: 'Quarterly', value: 92 }, { label: 'Yearly', value: 365 }],
      shipment: null,
      bulkShipments: [],
      driverId: null,
      dailyShipments: [],
      pickedDailyShipments: [],
      selected: [],
      toGenerate: [],
      drivers: [],
      dssList: [],
      dss: {},
      sealNo: null,
      tagNo: null,
      report: null,
      cusCodeSummary: null,
      transSummary: null,
      transSecSummary: null,
      cusMonths: null,
      summary: {},
      highPoints: [],
      recentOrders: [],
      period: 7,
      loading: false,
      report1Options: {
        chart: {
          id: 'report1'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      report2Options: {
        chart: {
          id: 'report2'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      report3Options: {
        chart: {
          id: 'report3'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      report1series: [
        {
          name: 'Express Code',
          data: []
        }
      ],
      report2series: [
        {
          name: 'Finance Status',
          data: []
        }
      ],
      report3series: [
        {
          name: 'Frontline',
          data: []
        }
      ],
      cusCodeOptions: {
        chart: {
          id: 'cusCode'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      transCodeOptions: {
        chart: {
          id: 'transCode'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      transFrontlineOptions: {
        chart: {
          id: 'transFrontline'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      holdShipmentOptions: {
        chart: {
          id: 'holdShipment'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      transSecOptions: {
        chart: {
          id: 'transSec'
        },
        xaxis: {
          categories: ['2014']
        },
        colors: ['#102060'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        }
      },
      cusBirthOptions: {
        labels: [],
        dataLabels: {
          enabled: false
        },
        colors: ['#B3F941', '#2DACDB', '#FF8C00', '#F9C74F', '#18FFC8', '#90BE6D', '#655B96', '#EFF32C', '#F94144', '#0034FF', '#9291A5', '#ED61AE'],
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400
        },
        xaxis: {
          position: 'bottom',
          title: {
            text: 'Revenue',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#18181B',
              fontSize: '10px',
              lineHeight: '13px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-title'
            }
          }
        },
        yaxis: {
          show: true,
          showAlways: true,
          showForNullSeries: true,
          title: {
            text: 'Frontline',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#18181B',
              fontSize: '10px',
              lineHeight: '13px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-title'
            }
          }
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            donut: {
              size: '65%'
            }
          }
        }
      },
      cusCodeSeries: [
        {
          name: '',
          data: []
        }
      ],
      transCodeSeries: [
        {
          name: '',
          data: []
        }
      ],
      transFrontlineSeries: [
        {
          name: '',
          data: []
        }
      ],
      holdShipmentSeries: [
        {
          name: '',
          data: []
        }
      ],
      transSecSeries: [
        {
          name: '',
          data: []
        }
      ],
      cusBirthSeries: []
    }
  },
  actions: {
    getNoOfCustByCode () {
      console.log(this.cusEndDate)
      saleService.getNoOfCusByCode(new Date(this.cusStartDate).toISOString(), new Date(this.cusEndDate).toISOString(), this.subsidiaryId)
        .then(
          c => {
            console.log('cusByCode >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getNoOfTransByCode () {
      saleService.getNoOfTransByCode(new Date(this.transStartDate).toISOString(), new Date(this.transEndDate).toISOString(), this.subsidiaryId)
        .then(
          c => {
            console.log('transByCode >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getNoOfTransByFrontline () {
      saleService.getNoOfTransByFrontline(new Date(this.transStartDate).toISOString(), new Date(this.transEndDate).toISOString(), this.subsidiaryId)
        .then(
          c => {
            console.log('transByFronline >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getNoOfHoldShipByFrontline () {
      saleService.getNoOfHoldShipmentByFrontline(this.subsidiaryId)
        .then(
          c => {
            console.log('transByFronline >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getNoOfTransBySector () {
      saleService.getNoOfTransBySector(new Date(this.sectorStartDate).toISOString(), new Date(this.sectorEndDate).toISOString(), this.subsidiaryId)
        .then(
          c => {
            console.log('transBySector >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getCustByMonth () {
      saleService.getCusByBirthMonth()
        .then(
          c => {
            console.log('cusByMonth >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getCustComm (id) {
      saleService.getCustomerComm(id)
        .then(
          c => {
            console.log('cusComm >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getCustFull (id) {
      saleService.getCustomerFull(id)
        .then(
          c => {
            console.log('cusFull >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadDiscount () {
      saleService.downloadDiscount()
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByOriginHubs (id) {
      saleService.downloadByOriginHubs(id, this.currentUser.sbuUser.sbuId, this.cusStartDate, this.cusEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByDestHubs (id) {
      saleService.downloadByDestHubs(id, this.currentUser.sbuUser.sbuId, this.transStartDate, this.transEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByTransit (id) {
      saleService.downloadByTransit(id, this.currentUser.sbuUser.sbuId, this.sectorStartDate, this.sectorEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByRegOriginHubs () {
      saleService.downloadByRegOriginHubs(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.cusStartDate, this.cusEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByRegDestHubs () {
      saleService.downloadByRegDestHubs(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.transStartDate, this.transEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadByRegTransit () {
      saleService.downloadByRegTransit(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.sectorStartDate, this.sectorEndDate)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    downloadDiscByHubs (id, type) {
      saleService.downloadDiscByHubs(id, this.currentUser.sbuUser.sbuId, type)
        .then(
          c => {
            console.log('discount >>', c)
            const blob = new Blob([c.data], { type: c.data.type })
            // For the processing of the IE browser, CreateObjectURL is not supported by some IE browser
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, c.fileName)
            } else {
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob) // Create a download link
              downloadElement.href = href
              downloadElement.download = c.fileName // Download the file name
              document.body.appendChild(downloadElement)
              downloadElement.click() // click to download
              document.body.removeChild(downloadElement) // Download complete removal element
              window.URL.revokeObjectURL(href) // Release Blob object
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    numberOfCusByCode () {
      saleService.numberOfCusByCode(this.cusStartDate, this.cusEndDate, this.subsidiaryId)
        .then(s => {
          console.log('cusCode', s)
          this.cusCodeSummary = s
          this.cusCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.cusCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    numberOfTransByCode () {
      saleService.numberOfTransByCode(this.transStartDate, this.transEndDate, this.subsidiaryId)
        .then(s => {
          console.log('transCode', s)
          this.transSummary = s
          this.transCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    numberOfTransByFrontline () {
      saleService.numberOfTransByFrontline(this.transStartDate, this.transEndDate, this.subsidiaryId)
        .then(s => {
          console.log('transCode', s)
          this.transSummary = s
          this.transFrontlineOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transFrontlineSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    numberOfHoldShipmentByFrontline () {
      saleService.numberOfHoldingShipmentByFrontline(this.subsidiaryId)
        .then(s => {
          console.log('transCode', s)
          this.transSummary = s
          this.holdShipmentOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.holdShipmentSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    numberOfTransBySector () {
      saleService.numberOfTransBySector(this.sectorStartDate, this.sectorEndDate, this.subsidiaryId)
        .then(s => {
          console.log('transSec', s)
          this.transSecSummary = s
          this.transSecOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transSecSeries = [{
            data: s.map(s => s.value)
          }]
          // this.report1series = [{
          //   data: c.numberOfTransactionsByCode.map(c => c.value)
          // }]
        }, error => {
          console.log(error)
        })
    },
    cusByMonth () {
      saleService.cusByBirthMonth()
        .then(s => {
          console.log('cusMonth', s)
          this.cusMonths = s
          this.cusBirthOptions = {
            labels: s.map(s => s.name)
          }
          this.cusBirthSeries = s.map(s => s.value)
        }, error => {
          console.log(error)
        })
    },
    noOfShipsAtOrigin (id) {
      saleService.shipmentsAtOrigin(id, this.currentUser.sbuUser.sbuId, this.cusStartDate, this.cusEndDate)
        .then(s => {
          console.log('cusCode', s)
          this.cusCodeSummary = s
          this.cusCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.cusCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    noOfShipsAtDest (id) {
      saleService.shipmentsAtDest(id, this.currentUser.sbuUser.sbuId, this.transStartDate, this.transEndDate)
        .then(s => {
          console.log('transCode', s)
          this.transSummary = s
          this.transCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    noOfShipsOnTransit (id) {
      saleService.ShipmentsOnTransit(id, this.currentUser.sbuUser.sbuId, this.sectorStartDate, this.sectorEndDate)
        .then(s => {
          console.log('transSec', s)
          this.transSecSummary = s
          this.transSecOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transSecSeries = [{
            data: s.map(s => s.value)
          }]
          // this.report1series = [{
          //   data: c.numberOfTransactionsByCode.map(c => c.value)
          // }]
        }, error => {
          console.log(error)
        })
    },
    noOfShipsAtRegOrigin () {
      saleService.shipmentsAtRegOrigin(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.cusStartDate, this.cusEndDate)
        .then(s => {
          console.log('cusCode', s)
          this.cusCodeSummary = s
          this.cusCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.cusCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    noOfShipsAtRegDest () {
      saleService.shipmentsAtRegDest(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.transStartDate, this.transEndDate)
        .then(s => {
          console.log('transCode', s)
          this.transSummary = s
          this.transCodeOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transCodeSeries = [{
            data: s.map(s => s.value)
          }]
        }, error => {
          console.log(error)
        })
    },
    noOfShipsOnRegTransit () {
      saleService.ShipmentsOnRegTransit(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.sectorStartDate, this.sectorEndDate)
        .then(s => {
          console.log('transSec', s)
          this.transSecSummary = s
          this.transSecOptions = {
            xaxis: {
              categories: s.map(s => s.name)
            }
          }
          this.transSecSeries = [{
            data: s.map(s => s.value)
          }]
          // this.report1series = [{
          //   data: c.numberOfTransactionsByCode.map(c => c.value)
          // }]
        }, error => {
          console.log(error)
        })
    },
    noOfDiscShipsByHubs (id, type) {
      saleService.discShipmentsByHubs(id, this.currentUser.sbuUser.sbuId, type)
        .then(s => {
          console.log('cusMonth', s)
          this.cusMonths = s
          this.cusBirthOptions = {
            labels: s.map(s => s.name)
          }
          this.cusBirthSeries = s.map(s => s.value)
        }, error => {
          console.log(error)
        })
    },
    frontlineSummary () {
      saleService.frontlineSummary(this.currentFrontline.expressCentre.code)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    marketerSummary () {
      saleService.marketerSummary((this.currentUser.sbuUser) ? this.currentUser.sbuUser.sbuId : null)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    lmsSummary () {
      saleService.lmsSummary(this.currentUser.sbuUser.sbuId, this.currentFrontline.hub.cityId, this.period)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    lmsSummaryByCity (cityId) {
      saleService.lmsSummary(this.currentUser.sbuUser.sbuId, cityId, this.period)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    operatorSummary (sbuId) {
      saleService.operatorSummary(sbuId, (sbuId !== 3) ? this.currentFrontline.hub.cityId : this.currentFrontline.airportId)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    managerSummary () {
      saleService.managerSummary(this.currentFrontline.area, this.currentUser.sbuUser.sbuId, this.currentFrontline.type)
        .then(s => {
          console.log('summary', s)
          this.summary = s
        }, error => {
          console.log(error)
        })
    },
    noOfSaleOrders () {
      saleService.totalSalesOrders(this.currentUser.sbuUser.sbuId, this.currentFrontline.expressCentre.code)
        .then(r => {
          console.log(r)
          this.recentOrders = r
        }, error => {
          console.log(error)
        })
    },
    highPointCus () {
      saleService.highPointCustomers(this.currentFrontline.expressCentre.code)
        .then(r => {
          console.log('cus', r)
          this.highPoints = r
        }, error => {
          console.log(error)
        })
    },
    getFSMReport (id) {
      saleService.getFsmReport(this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.report1Options = {
              xaxis: {
                categories: c.numberOfTransactionsByCode.map(c => c.name)
              }
            }
            this.report2Options = {
              xaxis: {
                categories: c.numberOfTransactionsBySector.map(c => c.name)
              }
            }
            this.report3Options = {
              xaxis: {
                categories: c.numberOfTransactionsByFrontline.map(c => c.name)
              }
            }
            this.report1series = [{
              data: c.numberOfTransactionsByCode.map(c => c.value)
            }]
            this.report2series = [{
              data: c.numberOfTransactionsBySector.map(c => c.value)
            }]
            this.report3series = [{
              data: c.numberOfTransactionsByFrontline.map(c => c.value)
            }]
          },
          e => {
            console.log(e)
          }
        )
    },
    getDomesticReport (id) {
      saleService.getDomesticReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.report1Options = {
              xaxis: {
                categories: c.numberOfTransactionsByCode.map(c => c.name)
              }
            }
            this.report2Options = {
              xaxis: {
                categories: c.numberOfTransactionsBySector.map(c => c.name)
              }
            }
            this.report3Options = {
              xaxis: {
                categories: c.numberOfTransactionsByFrontline.map(c => c.name)
              }
            }
            this.report1series = [{
              data: c.numberOfTransactionsByCode.map(c => c.value)
            }]
            this.report2series = [{
              data: c.numberOfTransactionsBySector.map(c => c.value)
            }]
            this.report3series = [{
              data: c.numberOfTransactionsByFrontline.map(c => c.value)
            }]
          },
          e => {
            console.log(e)
          }
        )
    },
    getFreightReport () {
      saleService.getFreightReport(this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.report1Options = {
              xaxis: {
                categories: c.numberOfTransactionsByCode.map(c => c.name)
              }
            }
            this.report2Options = {
              xaxis: {
                categories: c.numberOfTransactionsBySector.map(c => c.name)
              }
            }
            this.report3Options = {
              xaxis: {
                categories: c.numberOfTransactionsByFrontline.map(c => c.name)
              }
            }
            this.report1series = [{
              data: c.numberOfTransactionsByCode.map(c => c.value)
            }]
            this.report2series = [{
              data: c.numberOfTransactionsBySector.map(c => c.value)
            }]
            this.report3series = [{
              data: c.numberOfTransactionsByFrontline.map(c => c.value)
            }]
          },
          e => {
            console.log(e)
          }
        )
    },
    getAdminReport (id) {
      saleService.getAdminReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
          },
          e => {
            console.log(e)
          }
        )
    },
    getFSMReportByFrontline (id) {
      saleService.getFsmFrontlineReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getShipment (id) {
      saleService.getShipment(id)
        .then(
          c => {
            this.shipment = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getShipments (id) {
      saleService.getShipmentsDetails(id)
        .then(
          c => {
            this.bulkShipments = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getFShipment (id) {
      saleService.getFShipment(id)
        .then(
          c => {
            this.shipment = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getIShipment (id) {
      saleService.getIShipment(id)
        .then(
          c => {
            this.shipment = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getDailyReportByFrontline (id) {
      saleService.getDomesticFrontlineReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.dailyShipments = c.shipments.filter(c => c.status !== 1)
            this.pickedDailyShipments = c.shipments.filter(c => c.status === 1)
            console.log(c)
            for (const i in c.shipments) {
              console.log('fronId >>', c.shipments[i].shipment.frontlineId)
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getDomesticReportByFrontline (id, date) {
      saleService.getDomesticFrontlineReport(id, date, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.dailyShipments = c.shipments.filter(c => c.shipment.status === 0)
            this.pickedDailyShipments = c.shipments.filter(c => c.shipment.status > 0)
            console.log(c)
            for (const i in c.shipments) {
              console.log('fronId >>', c.shipments[i].shipment.frontlineId)
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getDomesticReportByCity (id, sbuId, date) {
      saleService.getDomesticReportByCity(id, sbuId, date, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.dailyShipments = c.shipments.filter(c => c.shipment.status === 0)
            this.pickedDailyShipments = c.shipments.filter(c => c.shipment.status > 0)
            console.log(c)
            for (const i in c.shipments) {
              console.log('fronId >>', c.shipments[i].shipment.frontlineId)
            }
          },
          e => {
            console.log(e)
          }
        )
    },
    getIntlReportByFrontline (id, date) {
      saleService.getFsmFrontlineReport(id, date, this.transEndDate)
        .then(
          c => {
            this.report = c
            this.dailyShipments = c.shipments.filter(c => c.shipment.status === 0)
            this.pickedDailyShipments = c.shipments.filter(c => c.shipment.status > 0)
            console.log(c)
            for (const i in c.shipments) {
              console.log('fronId >>', c.shipments[i].shipment.frontlineId)
            }
          },
          e => {
            console.log(e)
            alert(e)
          }
        )
    },
    getfnReport (id) {
      saleService.getfnReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getFreightReportByFrontline (id) {
      saleService.getFreightFrontlineReport(id, this.transStartDate, this.transEndDate)
        .then(
          c => {
            this.report = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getDrivers (cityId) {
      saleService.getDriver(this.currentFrontline.expressCentre.sbuId, cityId)
        .then(
          c => {
            this.drivers = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getDSS () {
      saleService.getDSS(this.currentFrontline.id)
        .then(
          c => {
            this.dssList = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getDSSByCity () {
      saleService.getDSSByCity(this.currentFrontline.hub.cityId)
        .then(
          c => {
            this.dssList = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    },
    getDSSDetail (id) {
      saleService.getDSSDetail(id)
        .then(
          c => {
            this.dss = c
            console.log(c)
          },
          e => {
            console.log(e)
          }
        )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSaleStore, import.meta.hot))
}
